import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';

@Component({
  selector: 'app-drawer-product-examine',
  templateUrl: './drawer-product-examine.component.html',
  styleUrls: ['./drawer-product-examine.component.less']
})
export class DrawerProductExamineComponent implements OnInit {
  @Input() value = '';

  constructor(
    public message: NzMessageService,
    public api: Api,
  ) { }
  contractNum = 0;
  ngOnInit() {
    this.getOrderProduct();
  }

  productInfo = {};
  // 获取产品信息
  getOrderProduct() {
    this.api.orderProductInfo(this.value).subscribe(res => {
      this.productInfo = res['data'];
    })
  }
}
