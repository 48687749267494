import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { DrawerOrderComponent } from './component/drawer-order/drawer-order.component';
import { DrawerTaskComponent } from './component/drawer-task/drawer-task.component';
import { DrawerUserComponent } from './component/drawer-user/drawer-user.component';
import { DrawerStoragesExamineComponent } from './component/drawer-storages-examine/drawer-storages-examine.component';
import { DrawerSignEnterpriseComponent } from './component/drawer-sign-enterprise/drawer-sign-enterprise.component';
import { DrawerRequesExamineComponent } from './component/drawer-reques-examine/drawer-reques-examine.component';
import { ModalAddAchievementComponent } from './component/modal-add-achievement/modal-add-achievement.component';
import { ModalAddcontractComponent } from './component/modal-addcontract/modal-addcontract.component';
import { ModalAddInvoiceComponent } from './component/modal-addInvoice/modal-addInvoice.component';
import { ModalAddtaskComponent } from './component/modal-addtask/modal-addtask.component';
import { ModalCompanyComponent } from './component/modal-company/modal-company.component';
import { ModalEditOrderModelComponent } from './component/modal-edit-order-model/modal-edit-order-model.component';
import { ModalEditTaskModelComponent } from './component/modal-edit-task-model/modal-edit-task-model.component';
import { ModalOrderComponent } from './component/modal-order/modal-order.component';
import { ModalNeworderComponent } from './component/modal-neworder/modal-neworder.component';
import { ModalPaymentComponent } from './component/modal-payment/modal-payment.component';
import { ModalSelectproductComponent } from './component/modal-selectproduct/modal-selectproduct.component';
import { ModalSelectproductSingleComponent } from './component/modal-selectproduct-single/modal-selectproduct-single.component';
import { ModalSourceConfigAddComponent } from './component/modal-source-config-add/modal-source-config-add.component';
import { ModalSourceConfigListComponent } from './component/modal-source-config-list/modal-source-config-list.component';
import { ModalUserComponent } from './component/modal-user/modal-user.component';
import { ModalUsercomsumItemComponent } from './component/modal-usercomsum-item/modal-usercomsum-item.component';
import { ModalUserConfigComponent } from './component/modal-userConfig/modal-userConfig.component';
import { ModalAddUserPerComponent } from './component/modal-userDataPer/modal-userDataPer.component';
import { ModalVerifyCommissionlist } from './component/modal-verify-commissionlist.component/modal-verify-commissionlist.component';
import { ModalWangeditorComponent } from './component/modal-wangeditor/modal-wangeditor.component';
import { ModalRecordeditorComponent } from './component/modal-recordeditor/modal-recordeditor.component';
import { ModalDeliveryWangeditorComponent } from './component/modal-deliverywangeditor/modal-deliverywangeditor.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { NoticetemplateComponent } from './system/notice/noticetemplate/noticetemplate.component';
import { ModalEditproductComponent } from './system/product-manage/modal-editproduct/modal-editproduct.component';
import { ModalEditproductAllComponent } from './system/product-all-manage/modal-editproduct-all/modal-editproduct-all.component';
import { ModalAddThreadPlanComponent } from './component/modal-add-thread-plan/modal-add-thread-plan.component';
import { ModalAddOfficeConfigComponent } from './component/modal-add-office-config/modal-add-office-config.component';
import { KnowledgeTemplateComponent } from './workplace/knowledge-base/knowledge-template/knowledge-template.component';
import { ModalAddAccountComponent } from './component/modal-add-account/modal-add-account.component';
import { ThreadStatisticalComponent } from './component/thread-statistical/thread-statistical.component';
import { ModalCheckComponent } from './component/modal-check/modal-check.component';
import { ModalCauseComponent } from './component/modal-cause/modal-cause.component';
import { ModalAddUserDataperComponent } from './component/modal-add-user-dataper/modal-add-user-dataper.component';
import { ModalThreadPlanCustomerListComponent } from './component/modal-thread-plan-customer-list/modal-thread-plan-customer-list.component';
import { ModalOpenImageComponent } from './component/modal-open-image/modal-open-image.component';
import { ModalOfficeConsumptionsListComponent } from './component/modal-office-consumptions-list/modal-office-consumptions-list.component';
import { ModelOfficeBalancebillComponent } from './component/model-office-balancebill/model-office-balancebill.component';
import { ModalCreateCooperationCustomerComponent } from './component/modal-create-cooperation-customer/modal-create-cooperation-customer.component';
import { ModalDistributorsComponent } from './system/product-manage/modal-distributors/modal-distributors.component';
import { ModalDistributorsCustomerComponent } from './component/modal-distributors-customer/modal-distributors-customer.component';
import { CreatePropertyDistributorsComponent } from './component/create-property-distributors/create-property-distributors.component';
import { FeedBackComponent } from "./component/feedback/feedback"
import { OfflinePaymentComponent } from './component/offline-payment/offline-payment.component'
import { ThreadStatisticalNameComponent } from "./component/thread-statistical-name/thread-statistical-name.component"
import { ThreadstatisticalApprovalComponent } from './component/thread-statistical-approval/thread-statistical-name.component';
import { ModalAddThreadPlannameComponent } from './component/modal-add-thread-planname/modal-add-thread-planname.component'
import { ModelExamineComponentComponent } from './component/model-examine-component/model-examine-component.component'
import { ModalAddSignEnterpriseComponent } from './component/modal-add-sign-enterprise/modal-add-sign-enterprise.component'
import { ModalExamineComponent } from './component/modal-examine/modal-examine.component';
import { ModalRequestAddOrderComponent } from './component/modal-request-add-order/modal-request-add-order.component'
import { ModalSelectRelationOrderComponent } from './component/modal-select-relation-order/modal-select-relation-order.component'
import { ModalSelectWorkOrderComponent } from './component/modal-select-work-order/modal-select-work-order.component'
import { DrawerServiceStoragesComponent } from './component/drawer-service-storages/drawer-service-storages.component'
import { ModalWorkDeliverComponent } from './component/modal-work-deliver/modal-work-deliver.component'
import { ModalRefundExamineComponent } from './component/modal-refund-examine/modal-refund-examine.component';
import { DrawerFlingSingleComponent } from './component/drawer-fling-single/drawer-fling-single.component';
import { ModalSelectFlingSingleComponent } from './component/modal-select-fling-single/modal-select-fling-single.component';
import { DrawerReimbursementsApplyComponent } from './component/drawer-reimbursements-apply/drawer-reimbursements-apply.component';

import { ModalReimbursWriteoffAddComponent } from './component/modal-reimburs-writeoff-add/modal-reimburs-writeoff-add.component';
import { DrawerReimbursWriteoffApplyComponent } from './component/drawer-reimburs-writeoff-apply/drawer-reimburs-writeoff-apply.component';

import { ModalVerifyOrderComponent } from './component/modal-verify-order/modal-verify-order.component';
import { DrawerStaffInfoComponent } from './component/drawer-staff-info/drawer-staff-info.component';
import { ModalStaffOperationComponent } from './component/modal-staff-operation/modal-staff-operation.component';
import { ModalPrepaymentComponent } from './component/modal-prepayment/modal-prepayment.component';
import { DrawerPrepaymentComponent } from './component/drawer-prepayment/drawer-prepayment.component';
import { ModalEditProductComponent } from './component/modal-edit-product/modal-edit-product.component';
import { DrawerDifferentWorkorderComponent } from './component/drawer-different-workorder/drawer-different-workorder.component';
import { ModalSelectDifferentWorkorderComponent } from './component/modal-select-different-workorder/modal-select-different-workorder.component';
import { ModalVerifyDifferentWorderoderComponent } from './component/modal-verify-different-worderoder/modal-verify-different-worderoder.component';
import { ModalExamineInvoicingComponent } from './component/modal-examine-invoicing/modal-examine-invoicing.component';
import { ModalAddRenewComponent } from './component/modal-add-renew/modal-add-renew.component';
import { DrawerOrderRenewComponent } from './component/drawer-order-renew/drawer-order-renew.component';
import { ModalAddFileComponent } from './component/modal-add-file/modal-add-file.component';
import { ModalAddShareComponent } from './component/modal-add-share/modal-add-share.component';
import { DrawerArchivesComponent } from './component/drawer-archives/drawer-archives.component';
import { DrawerCompanyComponent } from './component/drawer-company/drawer-company.component';
import { ModalSelectqualComponent } from './component/modal-selectqual/modal-selectqual.component';
import { DrawerSalaryApplicationComponent } from './component/drawer-salary-application/drawer-salary-application.component';
import { ModalPayslipComponent } from './component/modal-payslip/modal-payslip.component';
import { ModalAddFinanceComponent } from './component/modal-add-finance/modal-add-finance.component';
import { DrawerCardAllocationComponent } from './component/drawer-card-allocation/drawer-card-allocation.component';
import { DrawerCustomerApplyComponent } from './component/drawer-customer-apply/drawer-customer-apply.component';
import { ModalEditJwcxProductComponent } from './component/modal-edit-jwcx-product/modal-edit-jwcx-product.component';
import { ModalAddserviceComponent } from './component/modal-addservice/modal-addservice.component';
import { DrawerEntpriseServerComponent } from './component/drawer-entprise-server/drawer-entprise-server.component';
import { DrawerEntpriseServerRenewComponent } from './component/drawer-entprise-server-renew/drawer-entprise-server-renew.component';
import { DrawerTaskInfoComponent } from './component/drawer-task-info/drawer-task-info.component';
import { DrawerPayslipInfoComponent } from './component/drawer-payslip-info/drawer-payslip-info.component';
import { ModalConfigAchievementsComponent } from './component/modal-config-achievements/modal-config-achievements.component';
import { ModalSelectcustomerComponent } from './component/modal-selectcustomer/modal-selectcustomer.component';
import { ModalCustomerLossCheckComponent } from './component/modal-customer-loss-check/modal-customer-loss-check.component';
import { DrawerCustomerLossComponent } from './component/drawer-customer-loss/drawer-customer-loss.component';
import { DrawerCardSetMonthComponent } from './component/drawer-card-set-month/drawer-card-set-month.component';
import { ModalTechniqueWorkComponent } from './component/modal-technique-work/modal-technique-work.component';
import { ModalStakeholderConfigComponent } from './component/modal-stakeholder-config/modal-stakeholder-config.component';
import { ModalServerConfigAddComponent } from './component/modal-server-config-add/modal-server-config-add.component';
import { ModalDeliveryComponent } from './component/modal-delivery/modal-delivery.component';
import { ModalDeliveryYearComponent } from './component/modal-delivery-year/modal-delivery-year.component';
import { DrawerServerMyComponent } from './component/drawer-server-my/drawer-server-my.component';
import { DrawerServerAllDetailComponent } from './component/drawer-server-all-detail/drawer-server-all-detail.component';
import { DrawerProductExtraComponent } from './component/drawer-product-extra/drawer-product-extra.component';
import { ModalSelectOrderProductComponent } from './component/modal-select-order-product/modal-select-order-product.component';
import { ModalCustomerComponent } from './component/modal-customer/modal-customer.component';
import { DrawerPersonalExtraComponent } from './component/drawer-personal-extra/drawer-personal-extra.component';
import { ModalOrderExamineComponent } from './component/modal-order-examine/modal-order-examine.component';
import { DrawerOfficeConfigComponent } from './component/drawer-office-config/drawer-office-config.component';
import { ModalTransferCustomerComponent } from './component/modal-transfer-customer/modal-transfer-customer.component';
import { ModalTransferServerComponent } from './component/modal-transfer-server/modal-transfer-server.component';
import { ModalSalaryUserComponent } from './component/modal-salary-user/modal-salary-user.component';
import { DrawerOtherIncomeComponent } from './component/drawer-other-income/drawer-other-income.component';
import { FinanceConfigComponent } from './component/finance-config/finance-config.component';
import { ModalAddManageTargetComponent } from './component/modal-add-manage-target/modal-add-manage-target.component';
import { ModalSelectmycompanyComponent } from './component/modal-selectmycompany/modal-selectmycompany.component';
import { DrawerManageTargetReportComponent } from './component/drawer-manage-target-report/drawer-manage-target-report.component';
import { DrawerCustomerMaintainComponent } from './component/drawer-customer-maintain/drawer-customer-maintain.component';
import { DrawerRequesExamineBackComponent } from './component/drawer-reques-examine-back/drawer-reques-examine-back.component';
import { DrawerFinanceComponent } from './component/drawer-finance/drawer-finance.component';
import { DrawerWorkReportComponent } from './component/drawer-work-report/drawer-work-report.component';
import { DrawerWorkReportUserComponent } from './component/drawer-work-report-user/drawer-work-report-user.component';
import { ModalWorkReportUserComponent } from './component/modal-work-report-user/modal-work-report-user.component';
import { DrawerWorkReportDetailsComponent } from './component/drawer-work-report-details/drawer-work-report-details.component';
import { ModalCustomerGroupComponent } from './component/modal-customer-group/modal-customer-group.component';
import { DrawerCustomerGroupComponent } from './component/drawer-customer-group/drawer-customer-group.component';
import { DrawerPeopleSetMonthComponent } from './component/drawer-people-set-month/drawer-people-set-month.component';
import { ModalAddCouponComponent } from './system/product-coupon/modal-add-coupon/modal-add-coupon.component';
import { DrawerProductCouponComponent } from './component/drawer-product-coupon/drawer-product-coupon.component';
import { ModalSaleProductCouponComponent } from './component/modal-sale-product-coupon/modal-sale-product-coupon.component';
import { ModalTransferRecommendComponent } from './component/modal-transfer-recommend/modal-transfer-recommend.component';
import { DrawerTransferRecommedComponent } from './component/drawer-transfer-recommed/drawer-transfer-recommed.component';
import { DrawerTaskCollectComponent } from './component/drawer-task-collect/drawer-task-collect.component';
import { DrawerDepartmentStatisticsComponent } from './component/drawer-department-statistics/drawer-department-statistics.component';
import { DrawerTaskDepartmentStatisiticsComponent } from './component/drawer-task-department-statisitics/drawer-task-department-statisitics.component';
import { DrawerBusinessComponent } from './component/drawer-business/drawer-business.component';
import { DrawerBattlesComponent } from './component/drawer-battles/drawer-battles.component';
import { ModalSelectOldUserComponent } from './component/modal-select-old-user/modal-select-old-user.component';
import { DrawerCardDetailComponent } from './component/drawer-card-detail/drawer-card-detail.component';
import { DrawerManagementTargetComponent } from './component/drawer-management-target/drawer-management-target.component';
import { ModalRenewOrderComponent } from './component/modal-renew-order/modal-renew-order.component';
import { DrawerSalesStatisiticsComponent } from './component/drawer-sales-statisitics/drawer-sales-statisitics.component';
import { DrawerRelationCompanyComponent } from './component/drawer-relation-company/drawer-relation-company.component';
import { DrawerRenewDownComponent } from './component/drawer-renew-down/drawer-renew-down.component';
import { DrawerBugetManageComponent } from './component/drawer-buget-manage/drawer-buget-manage.component';
import { DrawerBugetUsedDetailComponent } from './component/drawer-buget-used-detail/drawer-buget-used-detail.component';
import { DrawerProjectDetailComponent } from './component/drawer-project-detail/drawer-project-detail.component';

import { DrawerArticleLookComponent } from './component/drawer-article-look/drawer-article-look.component';
import { DrawerArticleManageComponent } from './component/drawer-article-manage/drawer-article-manage.component';
import { DrawerArticleShareComponent } from './component/drawer-article-share/drawer-article-share.component';
import { DrawerBudgetFinanceComponent } from './component/drawer-budget-finance/drawer-budget-finance.component';
import { ModalAddserviceNewComponent } from './component/modal-addservice-new/modal-addservice-new.component';
import { ModalAddAffiliatesComponent } from './component/modal-add-affiliates/modal-add-affiliates.component';
import { ModalAddContactComponent } from './component/modal-add-contact/modal-add-contact.component';
import { ModalAddOutlookComponent } from './component/modal-add-outlook/modal-add-outlook.component';
import { ModalAddOutlookcustComponent } from './component/modal-add-outlookcust/modal-add-outlookcust.component';
import { DrawerProductExamineComponent } from './component/drawer-product-examine/drawer-product-examine.component';
import { DrawerTransferServerComponent } from './component/drawer-transfer-server/drawer-transfer-server.component';

const DRAWER = [
  DrawerUserComponent,
  DrawerOrderComponent,
  DrawerTaskComponent,
  ModalCompanyComponent,
  ModalOrderComponent,
  ModalNeworderComponent,
  ModalUserComponent,
  ModalSelectproductComponent,
  ModalSelectproductSingleComponent,
  ModalPaymentComponent,
  ModalAddcontractComponent,
  ModalAddtaskComponent,
  ModalCheckComponent,
  ModalCauseComponent,
  ModalWangeditorComponent,
  ModalRecordeditorComponent,
  ModalDeliveryWangeditorComponent,
  NoticetemplateComponent,
  KnowledgeTemplateComponent,
  ModalUserConfigComponent,
  ModalAddUserPerComponent,
  ModalAddAchievementComponent,
  ModalEditproductComponent,
  ModalEditproductAllComponent,
  ModalEditOrderModelComponent,
  ModalSourceConfigListComponent,
  ModalSourceConfigAddComponent,
  ModalUsercomsumItemComponent,
  ModalEditTaskModelComponent,
  ModalVerifyCommissionlist,
  ModalAddInvoiceComponent,
  ModalAddThreadPlanComponent,
  ModalAddOfficeConfigComponent,
  ModalAddAccountComponent,
  ThreadStatisticalComponent,
  ModalAddUserDataperComponent,
  ModalThreadPlanCustomerListComponent,
  ModalOpenImageComponent,
  ModalOfficeConsumptionsListComponent,
  ModelOfficeBalancebillComponent,
  ModalCreateCooperationCustomerComponent,
  ModalDistributorsComponent,
  ModalDistributorsCustomerComponent,
  CreatePropertyDistributorsComponent,
  FeedBackComponent,
  OfflinePaymentComponent,
  ThreadStatisticalNameComponent,
  ThreadstatisticalApprovalComponent,
  ModalAddThreadPlannameComponent,
  ModelExamineComponentComponent,
  ModalAddSignEnterpriseComponent,
  DrawerSignEnterpriseComponent,
  ModalExamineComponent,
  DrawerRequesExamineComponent,
  ModalRequestAddOrderComponent,
  DrawerStoragesExamineComponent,
  ModalSelectRelationOrderComponent,
  ModalSelectWorkOrderComponent,
  DrawerServiceStoragesComponent,
  ModalWorkDeliverComponent,
  ModalRefundExamineComponent,
  DrawerFlingSingleComponent,
  ModalSelectFlingSingleComponent,
  DrawerReimbursementsApplyComponent,
  ModalReimbursWriteoffAddComponent,
  DrawerReimbursWriteoffApplyComponent,
  ModalVerifyOrderComponent,
  DrawerStaffInfoComponent,
  ModalStaffOperationComponent,
  ModalPrepaymentComponent,
  DrawerPrepaymentComponent,
  ModalEditProductComponent,//编辑产品
  DrawerDifferentWorkorderComponent,//编辑产品
  ModalSelectDifferentWorkorderComponent,//
  ModalVerifyDifferentWorderoderComponent,//
  ModalExamineInvoicingComponent,//
  ModalAddRenewComponent,//
  DrawerOrderRenewComponent,
  ModalAddFileComponent,
  ModalAddShareComponent,
  DrawerArchivesComponent,
  DrawerCompanyComponent,
  ModalSelectqualComponent,
  DrawerSalaryApplicationComponent,
  ModalPayslipComponent,
  ModalAddFinanceComponent,
  DrawerCardAllocationComponent,
  DrawerCustomerApplyComponent,
  ModalEditJwcxProductComponent,
  ModalAddserviceComponent,
  DrawerEntpriseServerComponent,
  DrawerEntpriseServerRenewComponent,
  DrawerTaskInfoComponent,
  DrawerPayslipInfoComponent,
  ModalConfigAchievementsComponent,
  ModalSelectcustomerComponent,
  ModalCustomerLossCheckComponent,
  DrawerCustomerLossComponent,
  DrawerCardSetMonthComponent,
  ModalTechniqueWorkComponent,
  ModalStakeholderConfigComponent,
  ModalServerConfigAddComponent,
  ModalDeliveryComponent,
  ModalDeliveryYearComponent,
  DrawerServerMyComponent,
  DrawerServerAllDetailComponent,
  DrawerProductExtraComponent,
  ModalSelectOrderProductComponent,
  ModalCustomerComponent,
  DrawerPersonalExtraComponent,
  ModalOrderExamineComponent,
  DrawerOfficeConfigComponent,
  ModalTransferCustomerComponent,
  ModalTransferServerComponent,
  ModalSalaryUserComponent,
  DrawerOtherIncomeComponent,
  FinanceConfigComponent,
  ModalAddManageTargetComponent,
  ModalSelectmycompanyComponent,
  DrawerManageTargetReportComponent,
  DrawerCustomerMaintainComponent,
  DrawerRequesExamineBackComponent,
  DrawerFinanceComponent,
  DrawerWorkReportComponent,
  DrawerWorkReportUserComponent,
  ModalWorkReportUserComponent,
  DrawerWorkReportDetailsComponent,
  ModalCustomerGroupComponent,
  DrawerCustomerGroupComponent,
  DrawerPeopleSetMonthComponent,
  ModalAddCouponComponent,
  DrawerProductCouponComponent,
  ModalSaleProductCouponComponent,
  ModalTransferRecommendComponent,
  DrawerTransferRecommedComponent,
  DrawerTaskCollectComponent,
  DrawerDepartmentStatisticsComponent,
  DrawerTaskDepartmentStatisiticsComponent,
  DrawerBusinessComponent,
  DrawerBattlesComponent,
  ModalSelectOldUserComponent,
  DrawerCardDetailComponent,
  DrawerManagementTargetComponent,
  ModalRenewOrderComponent,
  DrawerSalesStatisiticsComponent,
  DrawerRelationCompanyComponent,
  DrawerRenewDownComponent,
  DrawerBugetManageComponent,
  DrawerBugetUsedDetailComponent,
  DrawerProjectDetailComponent,
  DrawerArticleLookComponent,
  DrawerArticleManageComponent,
  DrawerArticleShareComponent,
  DrawerBudgetFinanceComponent,
  ModalAddserviceNewComponent,
  ModalAddAffiliatesComponent,
  ModalAddContactComponent,
  ModalAddOutlookComponent,
  ModalAddOutlookcustComponent,
  DrawerProductExamineComponent,
  DrawerTransferServerComponent
];
const COMPONENTS = [
  PagesComponent,
  ...DRAWER,
];

@NgModule({
  imports: [
    SharedModule,
    PagesRoutingModule,
    DashboardModule
  ],
  entryComponents: [...DRAWER],
  declarations: [...COMPONENTS,],
  exports: [...COMPONENTS]
})
export class PagesModule { }
