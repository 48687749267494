import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerTaskComponent } from '../../component/drawer-task/drawer-task.component';
import { Api } from '@service/Api';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { DrawerCompanyComponent } from '../../component/drawer-company/drawer-company.component';

@Component({
  selector: 'app-drawer-sales-statisitics',
  templateUrl: './drawer-sales-statisitics.component.html',
  styleUrls: ['./drawer-sales-statisitics.component.less']
})
export class DrawerSalesStatisiticsComponent implements OnInit {


  @Input() data = '';
  @Input() statusArr = [];
  @Input() type;
  @Input() serverType;
  constructor(
    fb: FormBuilder,
    public api: Api,
    private drawerService: NzDrawerService,
    private modalService: NzModalService,
    public message: NzMessageService,
  ) {
    this.form = fb.group({
      userId: [null, Validators.required],
    });
  }
  form: FormGroup;
  listOfData = [];
  params = {
    userId: null,
    status: null,
  }
  ngOnInit() {
    this.params.userId = this.data['userId'];
    // this.getOrderTaskList();
    this.changeSelect(this.statusArr[0])
  }

  // 获取工单列表
  // getOrderTaskList() {
  //   var text = ''
  //   switch (text) {
  //     case 'accountCustomerCount':
  //       // 代账客户数量
  //       this.api.dzCustomerList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'accountCompanyCount':
  //       // 代账公司数量
  //       this.api.dzCompanyList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'fwzAccountPrice':
  //       // 每月服务产值
  //       this.api.dzCompanyList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'grossAmount':
  //       // 当月总业绩（毛利）
  //       this.api.grossAmountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'logoutCount':
  //       // 流失客户户数
  //       this.api.logoutList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'logoutAmount':
  //       // 流失客户金额
  //       this.api.logoutList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldNewAddPrice':
  //       // 代账客户产生的新增业绩
  //       this.api.oldNewAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldNewAddAccountCount':
  //       // 代账客户产生的新增代账户数
  //       this.api.oldNewAddAccountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldZjsPrice':
  //       // 代账客户产生的转介绍业绩
  //       this.api.oldZjsPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'zjsCjCustomerCount':
  //       // 代账客户转介绍产生的成交客户数
  //       this.api.oldZjsPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'zjsActionCount':
  //       // 代账客户产生转介绍行为的客户数
  //       this.api.zjsActionCountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'zjsCustomerCount':
  //       // 代账客户转介绍产生的客户数
  //       this.api.zjsActionCountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldZjsNewAccountCount':
  //       // 代账客户转介绍新签代账客户户数
  //       this.api.oldZjsNewAccountPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldZjsNewAccountPrice':
  //       // 代账客户转介绍新签代账业绩
  //       this.api.oldZjsNewAccountPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldNewAddAccountCount':
  //       // 代账客户（复购）新签代账户数
  //       this.api.oldNewAccountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'oldNewAccountPrice':
  //       // 代账客户（复购）新签代账业绩明细
  //       this.api.oldNewAccountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'xqCount':
  //       // 新签代账户数
  //       this.api.newAccountAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'xqPrice':
  //       // 新签代账业绩
  //       this.api.newAccountAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'swhXqPrice':
  //       // 税务合规新签业绩
  //       this.api.swhXqPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'gsXqPrice':
  //       // 工商新签业绩
  //       this.api.gsXqPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'kjdjXqPrice':
  //       // 会计到家新签业绩
  //       this.api.kjdjXqPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'yxCount':
  //       // 当期应续代账户数
  //       this.api.yxPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'yxPrice':
  //       // 当期应续代账金额
  //       this.api.yxPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'qfCount':
  //       // 累计欠费户数
  //       this.api.qfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'qfPrice':
  //       // 累计欠费金额
  //       this.api.qfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'xfCount':
  //       // 代账按期已续费户数
  //       this.api.xfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'xfPrice':
  //       // 代账按期已续费金额
  //       this.api.xfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'hqCount':
  //       // 代账回续户数
  //       this.api.hfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'hqPrice':
  //       // 代账回续金额
  //       this.api.hfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'tqXfCount':
  //       // 代账提前续费户数
  //       this.api.tqXfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'tqXfPrice':
  //       // 代账提前续费金额
  //       this.api.tqXfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'jumpCount':
  //       // 代账跨年续费户数
  //       this.api.jumpPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'jumpPrice':
  //       // 代账跨年续费金额
  //       this.api.jumpPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'otherRenewPrice':
  //       // 其他续费
  //       this.api.otherRenewList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'lowCompanyCount':
  //       // 代账降价户数
  //       this.api.lowPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'lowCompanyPrice':
  //       // 代账降价金额
  //       this.api.lowPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'logOffCount':
  //       // 内部注销户数
  //       this.api.logOffPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'logOffPrice':
  //       // 内部注销金额
  //       this.api.logOffPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'newAddPrice':
  //       // 新增
  //       this.api.newAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     case 'renewedPrice':
  //       // 代账已续费户数、代账已续费金额
  //       this.api.xfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.xfPriceList = res['data'];
  //       });
  //       this.api.hfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.hfPriceList = res['data'];
  //       });
  //       break;
  //     case 'totalAmount':
  //       // tab 先显示新增
  //       this.api.newAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
  //         this.listOfData = res['data'];
  //       });
  //       break;
  //     default:
  //       break;
  //   }


  // }
  // xfPriceList: [];
  // hfPriceList: [];
  selectedIndex = 0;
  selectTabset(e) {
    this.selectedIndex = e.index;
  }

  changeSelect(tab) {
    if (tab == 'accountCustomerCount') {
      // 代账客户数量
      this.api.dzCustomerList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'accountCompanyCount' || tab == 'fwzAccountPrice') {
      // 代账公司数量、每月服务产值
      this.api.dzCompanyList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'grossAmount') {
      // 当月总业绩（毛利）
      this.api.grossAmountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'logoutCount' || tab == 'logoutAmount') {
      // 流失客户户数、流失客户金额
      this.api.logoutList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'oldNewAddPrice') {
      // 代账客户产生的新增业绩
      this.api.oldNewAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'oldZjsPrice' || tab == 'zjsCjCustomerCount') {
      // 代账客户产生的转介绍业绩、代账客户转介绍产生的成交客户数
      this.api.oldZjsPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'zjsActionCount' || tab == 'zjsCustomerCount') {
      // 代账客户产生转介绍行为的客户数、代账客户转介绍产生的客户数
      this.api.zjsActionCountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'oldZjsNewAccountCount' || tab == 'oldZjsNewAccountPrice') {
      // 代账客户转介绍新签代账客户户数、代账客户转介绍新签代账业绩
      this.api.oldZjsNewAccountPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'oldNewAddAccountCount' || tab == 'oldNewAccountPrice') {
      // 代账客户（复购）新签代账户数、代账客户（复购）新签代账业绩明细、代账客户产生的新增代账户数
      this.api.oldNewAddAccountList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'xqCount' || tab == 'xqPrice') {
      // 新签代账户数、新签代账业绩
      this.api.newAccountAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'swhXqPrice') {
      // 税务合规新签业绩
      this.api.swhXqPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'gsXqPrice') {
      // 工商新签业绩
      this.api.gsXqPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'kjdjXqPrice') {
      // 会计到家新签业绩
      this.api.kjdjXqPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'yxCount' || tab == 'yxPrice') {
      // 当期应续代账户数、当期应续代账金额
      this.api.yxPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'qfCount' || tab == 'qfPrice') {
      // 累计欠费户数、累计欠费金额
      this.api.qfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'hqCount' || tab == 'hqPrice') {
      //代账回续户数、代账回续金额
      this.api.hfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'xfCount' || tab == 'xfPrice') {
      //代账按期已续费户数、代账按期已续费金额
      this.api.xfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'tqXfCount' || tab == 'tqXfPrice') {
      //代账提前续费户数、代账提前续费金额
      this.api.tqXfPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'jumpCount' || tab == 'jumpPrice') {
      //代账跨年续费户数、代账跨年续费金额
      this.api.jumpPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'otherRenewPrice') {
      // 其他续费
      this.api.otherRenewList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'lowCompanyCount' || tab == 'lowCompanyPrice') {
      // 代账降价户数、代账降价金额
      this.api.lowPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'logOffCount' || tab == 'logOffPrice') {
      // 内部注销户数、内部注销金额
      this.api.logOffPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'rollRenewed') {
      // 滚动已续数量和金额明细
      this.api.rollRenewedList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'rollRenew') {
      // 滚动应续数量和金额明细
      this.api.rollRenewList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'newAddPrice') {
      // 新增
      this.api.newAddPriceList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'hjRxVal') {
      // 新增
      this.api.rxList(this.data['id']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'unAccountCustomerCount') {
      // 非代账客户数量
      this.api.unAccountCustomerList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    } else if (tab == 'unAccountCompanyCount') {
      // 非代账公司数量
      this.api.unAccountCompanyList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'newCount' || tab == 'newPrice') {
      // 新增明细
      this.api.saleNewAddPriceList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'djZjsNewCount' || tab == 'djZjsNewPrice') {
      // 代账客户转介绍新签代账客户户数、代账客户转介绍新签代账业绩
      this.api.djZjsNewPriceList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'unDjZjsNewCount' || tab == 'unDjZjsNewPrice') {
      // 非代账客户转介绍新签代账客户户数、非代账客户转介绍新签代账业绩
      this.api.unDjZjsNewPriceList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'djNewCount' || tab == 'djNewPrice') {
      // 代账客户（复购）新签代账户数、代账客户（复购）新签业绩
      this.api.djNewList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'unDjNewCount' || tab == 'unDjNewPrice') {
      // 非代账客户（复购）新签代账户数、非代账客户（复购）新签代账业绩
      this.api.unOldNewAccountList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'zzkfNewCount' || tab == 'zzkfNewPrice') {
      // 自主开发新签代账户数、自主开发新签代账业绩
      this.api.zzkfNewList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'nbjsNewCount' || tab == 'nbjsNewPrice') {
      // 内部介绍新签代账户数、内部介绍新签代账业绩
      this.api.nbjsNewList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'jdkfNewCount' || tab == 'jdkfNewPrice') {
      // 渠道开发新签代账户数、渠道开发新签代账户数
      this.api.dkfNewList(this.data['userId'], this.data['statisticsMonth'], this.serverType).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'dayProfit') {
      // 每月数据统计
      this.api.grossAmountDayList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    }
    else if (tab == 'monthAll') {
      // 每月数据统计
      this.api.grossAmountMonthList(this.data['userId'], this.data['statisticsMonth']).subscribe(res => {
        this.listOfData = res['data'];
      });
    }

  }
  // 打开客户信息详情
  open(id) {
    const drawerRef = this.drawerService.create<DrawerTaskComponent, { value: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id
      }
    });

    drawerRef.afterClose.subscribe((data) => {
    });
  }

  // 打开公司信息详情
  openCompany(id, index, jobId) {
    const customerDrawer = this.drawerService.create<DrawerCompanyComponent, { value: string, index: number, jobId: any, plateValue: string }, string>({
      nzWidth: 900,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerCompanyComponent,
      nzContentParams: {
        value: id,
        index,
        jobId,
        plateValue: 'my-customer'
      }
    });
    customerDrawer.afterClose.subscribe((data) => {
    });
  };
}
