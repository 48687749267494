import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { ModalSelectproductComponent } from '../modal-selectproduct/modal-selectproduct.component';
import { ModalCompanyComponent } from '../modal-company/modal-company.component';
import { Api } from '@service/Api';
import { Router } from "@angular/router"

@Component({
  selector: 'app-modal-add-renew',
  templateUrl: './modal-add-renew.component.html',
  styleUrls: ['./modal-add-renew.component.less']
})
export class ModalAddRenewComponent implements OnInit {

  @Input() params: string;

  form: FormGroup;
  form2: FormGroup;
  productList = []; // 已选择产品

  bussCompanyList = [];
  loading = false;
  totalPayment = 0; // 总金额

  constructor(
    fb: FormBuilder,
    public api: Api,
    public message: NzMessageService,
    private modalService: NzModalService,
    private modal: NzModalRef,

  ) {
    this.form = fb.group({
      phone: [null, Validators.required],
      customerName: new FormControl({ value: null, disabled: true }),
      customerId: [null], // 客户ID
      remark: [null], // 备注
      totalPayment: [null], // 总应支付金额
      couponAmount: [null], // 已优惠金额
      orderAllAmount: [null], // 订单总金额：
      couponInfoId: [null], // 优惠券：
      orderProductRecordId: [null], // 续费ID
      bussCompanyId: [null, Validators.required], // 入账公司
      achievementArr: [null, Validators.required], // 业务归属
    });
  }
  // 回签，提前，当月,
  renewType = [
    {
      code: 0,
      name: '当月'
    },
    // {
    //   code: 1,
    //   name: '回签'
    // },
    {
      code: 2,
      name: '提前'
    }
  ]
  productType = [
    {
      code: 0,
      name: '新签'
    },
    {
      code: 1,
      name: '续费'
    },
    {
      code: 2,
      name: '回签'
    }
  ]
  loadDeptData: any;
  city_Data = [];//公司，部门 分摊中心
  getOfficeDepartTreeLists() {
    this.api.getOfficeDepartTrees().subscribe(res => {
      //this.shareOfficeList = res['data']
      var strs = res['data'];
      (strs || []).map(item => {
        if (item.children) {
          if (item.children.length > 0) {
            (item.children || []).map(child => {
              if (!child.children) {
                child.isLeaf = true // 下级
              }
            })
          }
        } else {
          item.isLeaf = true
        }
      })
      this.city_Data = strs;
    })
  }
  onChanges(values) {
    // if (values.length != 0) {
    //   this.form.get('achievementCompanyId').setValue(values[0]);
    //   this.form.get('achievementDeptId').setValue(values[1]);
    // } else {
    //   this.form.get('achievementCompanyId').setValue(null);
    //   this.form.get('achievementDeptId').setValue(null);
    // }
  }

  // 打开产品列表
  openProduct(): void {
    const modal = this.modalService.create({
      nzTitle: '添加产品',
      nzContent: ModalSelectproductComponent,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgba(245, 246, 249, 0)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((res) => {
      if (res) {
        res.map((item) => {
          item['companyId'] = null;
          item['productPeriod'] = item['serverPeriod'];
          item['realSalePrice'] = item['realPrice'] || 0;
          item['couponAmount'] = 0;
          item['hasCompanyId'] = '1';
          item['renewFlag'] = '0';
          // 单价
          item['periodAmount'] = item['realSalePrice'] / (item['productPeriod'] || 1);
          item['cost'] = item['cost'] == 0 ? null : item['cost'];
          item['productType'] = item['productType'];
          item['renewType'] = null;
        })
        this.productList = [...new Set([...this.productList, ...res])];
        this.getCoupon();
        this.getPriceTotal();
      }
    });
  }

  delete(i) {
    let newList = [];
    this.productList.forEach((item, index) => {
      // if (item.id !== id) {
      //   newList.push(item);
      // }
      if (i !== index) {
        newList.push(item);
      }
    });
    this.productList = newList;
    this.getCoupon();
    this.getPriceTotal();
  }
  itemAlert = false;
  productNmae: string;
  getItemRealPrice(data) {
    if (data.productNature != 0) {
      // 长期
      if (data.periodAmount < data.tipsPeriodAmount) {
        this.itemAlert = true;
        this.productNmae = '当前产品：' + "(" + data.name + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
      }
    } else {
      // 一次性
      if (data.realSalePrice < data.price) {
        this.itemAlert = true;
        this.productNmae = '当前产品：' + "(" + data.name + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
      }
    }
    this.getPriceTotal();
  }
  afterClose(): void {
    console.log('close');
    this.itemAlert = false;
  }

  // 计算实际销售价
  getPrice(data) {
    this.productList.map(item => {
      if (item.productNature != 0) {
        item.realSalePrice = item['periodAmount'] * (item['productPeriod'] + (item['givePeriod'] || 0));
      }
    })
    this.getItemRealPrice(data);
  }
  // 计算单价
  getPeriodAmount(data) {
    this.productList.map(item => {
      if (item.productNature != 0) {
        item['periodAmount'] = (item.realSalePrice / (item['productPeriod'] + (item['givePeriod'] || 0))).toFixed(2);
      }
    })
    this.getItemRealPrice(data);
  }

  getPriceTotal() {
    // this.orderAllAmount = 0;
    // this.productList.map(item => {
    //   this.orderAllAmount += Number(item.realSalePrice) * Number(item.amount);
    // })
    // // 计算优惠券
    this.changeCoupon(this.couponInfoId);
  }


  cancel() {
    this.modal.destroy();
  }

  isVisible = false;
  handleCancel() {
    this.isVisible = false;
    this.form.get('phone').setValue(null);
    this.form.get('customerName').setValue(null);
  }
  dataToStr(data) {
    var date = data;
    if (!date || !date.getFullYear) {
      return date;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m;
  }
  amounts = 0;
  submit() {
    this.amounts = 0;
    this.loading = true;
    // 更新表单状态
    Object.keys(this.form.controls).map((key) => {
      this.form.controls[key].markAsDirty();
      this.form.controls[key].updateValueAndValidity();
    });
    if (!this.form.valid) {
      this.loading = false;
      return
    }

    //普通新增订单
    let value = this.form.value;
    value.cost = 0;
    let isProductNature = true;
    value.orderProductRecordId = this.params['id'];
    value.orderAllAmount = this.orderAllAmount;
    value.couponAmount = this.couponAmount;
    value.totalPayment = this.totalPayment;
    value.couponInfoId = this.couponInfoId;
    this.productList.map(item => {
      item.couponInfoId = this.couponInfoId;
    });
    value.productList = this.productList;
    value.achievementCompanyId = value.achievementArr['0'];
    value.achievementDeptId = value.achievementArr['1'];
    (this.city_Data || []).map(item => {
      if (item.id == value.achievementCompanyId) {
        value.achievementCompanyName = item.name;
        (item.children || []).map(child => {
          if (child.id == value.achievementDeptId) {
            value.achievementDeptName = child.name;
          }
        })
      }
    });


    if (value.productList.length <= 0) {
      this.message.error('请先添加产品！');
      this.loading = false;
      return
    };
    let hasPrice = false;
    let hasCompany = false;
    let hasRenewType = false;
    value.productList.map(item => {
      if (item.amount == "") {
        this.amounts = 1;
      }
      if (!item.realSalePrice) {
        hasPrice = true;
      }
      if (item.hasCompanyId == '1' && !item.companyId) {
        hasCompany = true;
      }
      if (item.hasCompanyId == '0') {
        item.companyId = null;
      }
      if (!item.renewType) {
        hasRenewType = true;
      }
      //判断产品价格 当前“实际销售价（单价）” 已低于 “市场指导价（单价）”，请先与上级领导核实
      // 长期
      if (item.productNature != 0) {
        if (item.periodAmount < item.tipsPeriodAmount) {
          value.priceStatus = 4;
        }
      }
      // 一次性
      if (item.productNature != 1) {
        if (item.realSalePrice < item.price) {
          value.priceStatus = 4;
        }
      }
      // if (value.productList[0].productNature != item.productNature) {
      //   this.message.error('一次性产品和长期产品不能同时下单，请重新选择', {
      //     nzDuration: 5000
      //   })
      //   isProductNature = false;
      //   this.loading = false;
      //   return
      // }
      // if (item.renewFlag == 1 && item.renewType == null) {
      //   isProductNature = false;
      //   this.loading = false;
      //   this.message.error('请选择续费类型')
      //   return
      // }
      if (item.productNature != 0) {
        item['serverPeriod'] = (item['productPeriod'] || 0) + (item['givePeriod'] || 0);
        item.startDate = this.dataToStr(item.startDate)
        if (!item.startDate) {
          isProductNature = false;
          this.loading = false;
          this.message.error('请选择服务开启时间！');
          return
        }
      }
      if (item.cost == null || item.cost === '') {
        isProductNature = false;
        this.loading = false;
        this.message.error('请填写产品成本！');
        return
      }
      item.realMonthPrice = Number((item.realSalePrice || 0) / (item.productPeriod || 1))
      value.cost += item.cost || 0;
    });

    if (!isProductNature) {
      return
    }
    if (this.amounts == 1) {
      this.message.error('请填写产品数量！');
      this.loading = false;
      return;
    }
    if (hasPrice) {
      this.message.error('实际销售价不能为0！');
      this.loading = false;
      return;
    }
    if (hasCompany) {
      this.message.error('请选择客户公司！');
      this.loading = false;
      return;
    }
    if (hasRenewType) {
      this.message.error('请选择类型！');
      this.loading = false;
      return;
    }
    var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(this.form.value['phone'])) {
      this.message.error('手机号码不符合规范!');
      this.loading = false;
      return
    }

    (this.bussCompanyList || []).map(item => {
      if (value.bussCompanyId == item.signedEnterpriseId) {
        value.bussCompanyName = item.fullNameOffice
        return
      }
    })

    this.api.addOrder(value).subscribe(res => {
      this.modal.destroy();
      this.message.success('创建成功！');
    }, () => this.loading = false);
  }

  ngOnInit() {
    this.api.getCustomerDetail({
      id: this.params['customerId'],
      type: 0
    }).subscribe(res => {
      this.form.get('phone').setValue(res['data'].phoneNumber);
      this.form.get('customerName').setValue(res['data'].name);
      this.form.get('customerId').setValue(this.params['customerId']);
    })
    this.getCompanyList();
    this.getCompanyData();
    let userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.form.get('achievementArr').setValue([userObj.dept.companyId, userObj.dept.id]);
    this.getOfficeDepartTreeLists();
    this.getRenewDetail();
  }

  //获取公司信息
  getCompanyData() {
    this.api.getUserReimbursementsApplyCompanies().subscribe(res => {
      this.bussCompanyList = res['data'];
    })
  }

  // 续费产品
  getRenewDetail() {
    if (this.params['orderProductRecordId']) {

      this.api.renewOrderDetails(this.params['orderProductRecordId']).subscribe(res => {
        if (res['data']) {
          this.api.findRenewFlag({ companyId: res['data'].companyId, cateId: res['data'].cateId }).subscribe(res2 => {
            const parts = res2['data'].split("_");
            const renewFlag = parts[0];
            let renewType = null;
            if (parts[0] == 1) {
              renewType = parts[1];
            }

            var resProduct = [{
              name: res['data'].categoryName,
              areaProductId: res['data'].areaProductId,
              realSalePrice: res['data'].realPrice || 0,
              couponAmount: 0,
              realPrice: res['data'].realPrice || 0,
              price: res['data'].price,
              cost: res['data'].cost,
              amount: 1,
              id: res['data'].areaProductId,
              productNature: res['data'].productNature,
              productPeriod: res['data'].productPeriod,
              hasCompanyId: res['data'].companyId ? '1' : '0',
              companyId: res['data'].companyId,
              settlePrice: res['data'].settlePrice,
              renewFlag: renewFlag,
              t: res['data'].cateName,
              cateId: res['data'].cateId,
              tipsPeriodAmount: res['data'].tipsPeriodAmount,
              // 单价
              // periodAmount: res['data']['realSalePrice'] / (res['data']['productPeriod'] || 1),
              periodAmount: res['data']['periodAmount'],
              productType: res['data']['productType'],
              renewType: renewType,
            }];
            this.productList = [...new Set([...this.productList, ...resProduct])];
            // this.productList.map((item) => {
            //   // item.renewFlag = 1;
            //   item['hasCompanyId'] = 1;
            // })
            this.getPriceTotal();
            this.getCoupon();
          })
        }
      })
    }

  }
  // 添加公司
  // 获取公司列表
  companyList = [];//公司列表
  getCompanyList() {
    this.api.getCompanyList({ id: this.params['customerId'] }).subscribe(res => {
      this.companyList = res['data'];
    })
  }
  // 新建公司
  createComponentModal(id?): void {
    const title = id ? '编辑公司' : '添加公司';
    const modal = this.modalService.create({
      nzTitle: title,
      nzContent: ModalCompanyComponent,
      nzComponentParams: {
        customerId: this.params['customerId'],
        id: id
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => {
      this.getCompanyList();
    });
  }
  // 获取续费标记
  getFindRenewFlag(data, i) {
    if (data.companyId) {
      this.api.findRenewFlag({ companyId: data.companyId, cateId: data.cateId }).subscribe(res => {
        const parts = res['data'].split("_");
        this.productList.map((item, index) => {
          if (index == i) {
            item.renewFlag = parts[0];
            item.renewType = null;
            if (parts[0] == 1) {
              item.renewType = parts[1];
            }
          }
        })
      })
    }
  }
  getHasCompanyId(data, i) {
    if (data.hasCompanyId == 1) {
      this.getFindRenewFlag(data, i)
    } else {
      data.renewFlag = '0';
    }
  }
  // 弹出窗逻辑 报销名目 start
  isVisibleGs = false
  desInfo = null;
  showModal(index): void {
    switch (index) {
      case 1:
        this.desInfo = '此产品所关联的客户公司,请务必填写。如客户公司未录入到系统，请及时录入再选择!'
        break;
      default:
        break;
    }
    this.isVisibleGs = true;
  }
  handleCancelGs(): void {
    this.isVisibleGs = false;
  }
  // 弹出窗逻辑 报销名目 en

  // 去重数组
  unique(arr) {
    return Array.from(new Set(arr))
  }
  // 获取用户可用优惠券
  couponInfoId; //优惠券id
  couponAmount = 0;//已优惠金额
  orderAllAmount = 0;//订单总金额：
  couponList: [];
  getCoupon() {
    this.couponInfoId = null;
    this.couponList = [];
    var params = {
      areaProductIds: [],
      cateIds: [],
      customerId: this.params['customerId'],
    }
    if (this.productList.length == 0) {
      this.couponList = [];
      return;
    }
    this.productList.map((item, index) => {
      params.areaProductIds.push(item.id);
      params.cateIds.push(item.cateId);
      item.couponAmount = 0;
      item.realPrice = item.realSalePrice - Number(item.couponAmount);
    })
    params.areaProductIds = this.unique(params.areaProductIds);
    params.cateIds = this.unique(params.cateIds);
    this.api.customerAvailableCouponList(params).subscribe(res => {
      this.couponList = res['data'];
    })
  }
  // 选择优惠券
  changeCoupon(e) {
    let arr = e ? this.couponList.filter(item => item['id'] == e)[0] : {};
    // 现金券只计算一次，折扣券 相关的产品都可以计算
    this.orderAllAmount = 0;
    this.couponAmount = 0;
    this.totalPayment = 0;
    this.productList.map((item, index) => {
      this.orderAllAmount += Number(item.realSalePrice) * Number(item.amount);
      item.couponAmount = 0;
      item.realPrice = item.realSalePrice - Number(item.couponAmount);
      switch (arr['productUse']) {
        case 0:
          // 通用
          if (index == 0) {
            if (arr['couponType'] == '1') {
              // 现金券
              item.couponAmount = arr['couponVal'];
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
              arr = {};
            }
            if (arr['couponType'] == '2') {
              // 折扣券
              item.couponAmount = (item.realSalePrice * (arr['couponVal'] / 100)).toFixed(2);
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
            }
          }
          break;
        case 1:
          // 指定到分类
          if (item.cateId == arr['cateId']) {
            if (arr['couponType'] == '1') {
              // 现金券
              item.couponAmount = arr['couponVal'];
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
              arr = {};
            }
            if (arr['couponType'] == '2') {
              // 折扣券
              item.couponAmount = (item.realSalePrice * (arr['couponVal'] / 100)).toFixed(2);
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
            }
          }
          break;
        case 2:
          // 指定到产品
          if (item.id == arr['areaProductId']) {
            if (arr['couponType'] == '1') {
              // 现金券
              item.couponAmount = arr['couponVal'];
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
              arr = {};
            }
            if (arr['couponType'] == '2') {
              // 折扣券
              item.couponAmount = (item.realSalePrice * (arr['couponVal'] / 100)).toFixed(2);
              item.realPrice = item.realSalePrice - Number(item.couponAmount);
            }
          }
          break;
        default:
          break;
      }
      this.couponAmount += Number(item.couponAmount);
      this.totalPayment += Number(item.realPrice);
    })
  }

  // 续费
  renewTypeList = [
    { id: '1', name: '正常续费' },
    { id: '11', name: '提前续费' },
    { id: '2', name: '回签' },
  ]
  //新增
  renewTypeAddList = [
    { id: '3', name: '代账客户复购' },
    { id: '4', name: '代账客户转介绍' },
    { id: '5', name: '非代账客户复购' },
    { id: '6', name: '非代账客户转介绍' },
    { id: '7', name: '美团' },
    { id: '8', name: '高德' },
    { id: '9', name: '公司资源' },
    { id: '94', name: '内部介绍' },
    { id: '97', name: '自主开发' },
    { id: '96', name: '渠道开发' },
  ]
}
